<template>
  <div>
    <!--begin::Detail-->
    <b-card header-tag="header" footer-tag="footer" class="gutter-b">
      <div class="row">
        <div class="col-md-12">
          <form ref="form">
            <b-form-group id="input-group-2" label="Judul" label-for="input-2">
              <b-form-input
                id="textarea"
                v-model="form.title"
                placeholder="Masukan informasi disini"
                rows="3"
                max-rows="6"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Syarat & Ketentuan"
              label-for="input-2"
            >
              <quill-editor
                ref="myQuillEditor"
                v-model="form.content"
                :options="editorOption"
              />
              <!-- <b-form-textarea
                id="textarea"
                v-model="form.desc"
                placeholder="Masukan informasi disini"
                rows="3"
                max-rows="6"
              ></b-form-textarea> -->
            </b-form-group>
          </form>
        </div>
      </div>
    </b-card>
    <b-button variant="secondary" @click="$router.go(-1)">Back</b-button>
    <b-button variant="primary" @click="saveTnc" class="ml-2">Save</b-button>
    <!--end::Detail-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { formatDate } from "@/utils/helper";

export default {
  name: "TNC",
  data() {
    return {
      form: {
        title: "",
        content: "<h2>I am Example</h2>",
      },
      id: "",
      slug: "",
      editorOption: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Syarat & Ketentuan" },
    ]);
  },
  created() {
    this.initData();
  },
  methods: {
    formatDate,
    initData() {
      try {
        this.$axios
          .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/page/slug/tnc`)
          .then(({ data }) => {
            this.form = {
              title: data.title,
              content: data.content,
            };
            this.id = data.id;
            this.slug = data.slug;
          });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    saveTnc() {
      try {
        this.$axios.put(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/page/${this.id}`,
          { ...this.form, lang: "ind", slug: this.slug }
        );
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      }
    },
    onEditorChange({ html }) {
      this.content = html;
    },
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
};
</script>
<style lang="scss"></style>
